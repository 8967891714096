import axios from "axios";

const state = {
    allModels: {},
    DataResponse: {},
    AllCount: 0,
    selectedModel: {},
    allMakes: [],
    allBodys: [],
    AllModels: [],
    AllTransmission: [],
    AllColors: [],
    AllEngine: [],
    imagesList: {},
    loadingDone: false,
    allSearch: {
        price: [0, 899900],
        mileage: [0, 355000],
        year: [2016, 2023],
        make: "",
        body: "",
        model: "",
        transmission: "",
        drives: "",
        color: "",
        engine: "",
    },
    Variants: {
        FullFriendlyName: "",
        "StockId": 0,
        "StockCode": "",
        "Mileage": 0,
        "Year": 0,
        "Price": 0,
        "WasPrice": 0,
        "Colour": "White",
        "FullServiceHistory": true,
        "Trim": "",
        "Registration": "",
        "VIN": "",
        "Department": "",
        "DateInStock": "",
        "Condition": "",
        "Comments": "",
        "Extras": "",
        "TransunionReference": "",
        "VehicleType": "",
        "SpinCode": "",
        "SpinVideoLink": "",
        "Mileage": 0,
        "IsPOA": false,
        Shape: {
            Name: ""
        },
    }




    // Variants: {
    //     FullFriendlyName: "",
    //     Shape: {
    //         Name: ""
    //     },
    //     Vehicles: [{
    //         "StockId": 0,
    //         "StockCode": "",
    //         "Mileage": 0,
    //         "Year": 0,
    //         "Price": 0,
    //         "WasPrice": 0,
    //         "Colour": "White",
    //         "FullServiceHistory": true,
    //         "Trim": "",
    //         "Registration": "",
    //         "VIN": "",
    //         "Department": "",
    //         "DateInStock": "",
    //         "Condition": "",
    //         "Comments": "",
    //         "Extras": "",
    //         "TransunionReference": "",
    //         "VehicleType": "",
    //         "SpinCode": "",
    //         "SpinVideoLink": "",
    //         "IsPOA": false
    //     }]

    // }

}
const getters = {
    allModels: (state) => state.allModels,
    DataResponse: (state) => state.DataResponse,
    allSearch: (state) => state.allSearch,
    selectedModel: (state) => state.selectedModel,
    imagesList: (state) => state.imagesList,
    Variants: (state) => state.Variants,
    allMakes: (state) => state.allMakes,
    allBodys: (state) => state.allBodys,
    AllModels: (state) => state.AllModels,
    AllTransmission: (state) => state.AllTransmission,
    AllColors: (state) => state.AllColors,
    AllEngine: (state) => state.AllEngine,
    AllCount: (state) => state.AllCount,
    loadingDone: (state) => state.loadingDone,
}

const actions = {

    refreshData({ commit }) {

        // console.log("54545sssss")

        const response = state.DataResponse;
        let _AllCount = 0
        let _allMakes = ["ALL Makes"]
        let _allBodys = ["All Body"]
        let _AllModels = ["All Models"]
        let _AllTransmission = ["All Transmission"]
        let _AllColors = ["All Colors"]
        let _AllEngine = ["All Engine"]
        for (const key in response.data[0].Makes) {
            if (Object.hasOwnProperty.call(response.data[0].Makes, key)) {


                // console.log(this.allSearch)
                const element = response.data[0].Makes[key];
                _allMakes.push(element.Name)

                if (state.allSearch.make === element.Name || state.allSearch.make == "" || state.allSearch.make == "ALL Makes") {
                    // console.log(element.Name, state.allSearch.make)

                    for (const _key in element.Models) {
                        if (Object.hasOwnProperty.call(element.Models, _key)) {


                            const ele = element.Models[_key];
                            // console.log(state.allSearch.year)
                            ele.Variants.forEach(velement => {
                                velement.Vehicles.forEach(xelement => {

                                    // console.log(xelement.Mileage, state.allSearch.mileage)
                                    if (!_allBodys.includes(velement.Shape.Name))
                                        _allBodys.push(velement.Shape.Name)

                                    if (!_AllTransmission.includes(xelement.Trim))

                                        _AllTransmission.push(xelement.Trim)

                                    if (!_AllModels.includes(ele.Name))

                                        _AllModels.push(ele.Name)
                                    if (!_AllColors.includes(xelement.Colour))

                                        _AllColors.push(xelement.Colour)
                                    if (!_AllEngine.includes(xelement.Department))

                                        _AllEngine.push(xelement.Department)


                                    if ((state.allSearch.body == velement.Shape.Name || state.allSearch.body == '' || state.allSearch.body == 'All Body') &&
                                        (state.allSearch.model == ele.Name || state.allSearch.model == '' || state.allSearch.model == 'All Models') &&
                                        (state.allSearch.color == xelement.Colour || state.allSearch.color == '' || state.allSearch.color == 'All Colors') &&
                                        (state.allSearch.engine == xelement.Department || state.allSearch.engine == '' || state.allSearch.engine == 'All Engine')

                                        &&
                                        ((state.allSearch.price[1] >= xelement.Price && state.allSearch.price[0] <= xelement.Price) || state.allSearch.price == '' || state.allSearch.price == 0)

                                        &&
                                        ((state.allSearch.mileage[1] >= xelement.Mileage && state.allSearch.mileage[0] <= xelement.Mileage) || state.allSearch.mileage == '' || state.allSearch.mileage == 0)

                                        &&
                                        ((state.allSearch.year[1] >= xelement.Year && state.allSearch.year[0] <= xelement.Year) || state.allSearch.year == '' || state.allSearch.year == 0)
                                    )
                                        _AllCount++
                                });
                            });


                            // console.log(ele)
                        }
                    }
                }


            }
        }

        // console.log(_AllCount)
        commit('setallMakes', _allMakes);
        commit('setallBodys', _allBodys);
        commit('setAllTransmission', _AllTransmission);
        commit('setAllModels', _AllModels);
        commit('setAllColors', _AllColors);
        commit('setAllEngine', _AllEngine);
        commit('setAllCount', _AllCount);
    },
    async fetchModels({ commit }, dataParams) {
        commit('setloadingDone', false);

        // console.log("zzzzzzz")
        var countercount = 0;
        let urlPrams = "empty=1"
        for (const key in dataParams) {
            if (Object.hasOwnProperty.call(dataParams, key)) {
                const elem = dataParams[key];
                urlPrams += "&" + key + "=" + elem
            }
        }

        const response = await axios.get('https://preownedec.davinci-cms.com/po/?' + urlPrams)
        let _AllCount = 0
        let _allMakes = ["ALL Makes"]
        let _allBodys = ["All Body"]
        let _AllModels = ["All Models"]
        let _AllTransmission = ["All Transmission"]
        let _AllColors = ["All Colors"]
        let _AllEngine = ["All Engine"]
        for (const key in response.data[0].Makes) {
            if (Object.hasOwnProperty.call(response.data[0].Makes, key)) {

                // if()
                // console.log(this.allSearch)
                const element = response.data[0].Makes[key];

                _allMakes.push(element.Name)

                for (const _key in element.Models) {
                    if (Object.hasOwnProperty.call(element.Models, _key)) {
                        const ele = element.Models[_key];
                        //console.log(ele.Variants[0].Vehicles[0])

                        ele.Variants.forEach(zele => {

                            zele.Vehicles.forEach(dele => {
                                // console.log(dele.StockCode, dele.StockId, countercount++)
                                if (!_allBodys.includes(zele.Shape.Name))
                                    _allBodys.push(zele.Shape.Name)

                                if (!_AllModels.includes(ele.Name))
                                    _AllModels.push(ele.Name)
                                if (!_AllTransmission.includes(dele.Trim))
                                    _AllTransmission.push(dele.Trim)

                                if (!_AllColors.includes(dele.Colour))

                                    _AllColors.push(dele.Colour)
                                if (!_AllEngine.includes(dele.Department))

                                    _AllEngine.push(dele.Department)
                                _AllCount++
                            });
                        });


                        // if (!_allBodys.includes(ele.Variants[0].Shape.Name))
                        //     _allBodys.push(ele.Variants[0].Shape.Name)

                        // if (!_AllTransmission.includes(ele.Variants[0].Vehicles[0].Trim))

                        //     _AllTransmission.push(ele.Variants[0].Vehicles[0].Trim)

                        // if (!_AllModels.includes(ele.Name))

                        //     _AllModels.push(ele.Name)
                        // if (!_AllColors.includes(ele.Variants[0].Vehicles[0].Colour))

                        //     _AllColors.push(ele.Variants[0].Vehicles[0].Colour)
                        // if (!_AllEngine.includes(ele.Variants[0].Vehicles[0].Department))

                        //     _AllEngine.push(ele.Variants[0].Vehicles[0].Department)

                        // _AllCount++

                        // console.log(ele)
                    }
                }


            }
        }
        // console.log(response.data[0].Makes, "8888888888888")
        commit('setModels', response.data[0].Makes);



        commit('DataResponse', response);
        commit('setallMakes', _allMakes);

        commit('setallBodys', _allBodys);
        commit('setAllTransmission', _AllTransmission);
        commit('setAllModels', _AllModels);
        commit('setAllColors', _AllColors);
        commit('setAllEngine', _AllEngine);
        commit('setAllCount', _AllCount);
        commit('setloadingDone', true);



    },

    filteredList({ commit, state }, params) {

        const Id = parseInt(params[0])
        const Stockid = parseInt(params[1])

        // console.log("sdsdsdsdsd ")
        commit('setloadingDone', false);
        // selectedModel
        var selectedM;
        var selectedModel;
        state.allModels.forEach(ele => {
            ele.Models.forEach(element => {
                element.Variants.forEach(zelement => {
                    zelement.Vehicles.forEach(delement => {

                        if ((Stockid === delement.StockId)) {
                            selectedM = zelement;
                            selectedModel = delement
                        }
                    })
                });
                // if (element.Id == Id) {
                //     selectedModel = element
                // }
            });
        });


        // selectedModel = state.allModels.filter(item => {
        //     for (const key in item.Models) {

        //         if (Object.hasOwnProperty.call(item.Models, key)) {

        //             const element = item.Models[key];

        //             element.Variants.forEach(zelement => {
        //                 zelement.Vehicles.forEach(delement => {
        //                     console.log(Stockid === delement.StockId)

        //                     ((Stockid === delement.StockId))
        //                 })
        //             });

        //         }



        //     }

        // })
        // console.log(selectedM);

        selectedModel["FullFriendlyName"] = selectedM.FullFriendlyName;
        selectedModel["Shape"] = selectedM.Shape;
        commit('setloadingDone', true);
        // console.log(selectedModel, "ssss")
        commit('setSelectedModel', selectedModel);
        commit('setimagesList', selectedModel.Images);
        commit('setVariants', selectedModel);



    },


    setSearchValue({
        commit,

    }, value) {

        console.log(value, 'setSearchValue zzzz')
        commit('setSearch', value);
    },

    rangeFilter({
        commit,
        state
    }, rangeValue) {

        // console.log(rangeValue, 'sssssss')

        // return false;

        let obj = [];
        obj = [...state.allModels]
            // console.log("zzzzzwwssss")
        let filterItem = JSON.parse(JSON.stringify(obj));
        // console.log(filterItem, "------");
        var filteredModel = [];
        for (const key in filterItem) {
            // console.log(filterItem[key]);
            for (const Innerkey in filterItem[key].Models) {
                var models = filterItem[Innerkey].Models;

                for (const child in models) {
                    // console.log(models[child]);
                    for (const child in models) {
                        filteredModel = models[child].Variants.filter(item => {
                            item.Vehicles.filter(
                                    item => {
                                        // console.log(item.Price, rangeValue[1][0], angeValue[1][1])
                                        return (item.Price >= rangeValue[1][0] && item.Price <= rangeValue[1][1])
                                    }
                                )
                                // return item.Vehicles[0].Price >= rangeValue && item.Vehicles[0].Price <= 853900
                        })


                    }
                }
            }
        }
        // console.log(filteredModel, "filteredModelfilteredModelfilteredModelfilteredModel");
        // let filteredModel = vehicles[0].filter(item => { 

        //       return item.Variants[0].Vehicles[0].Price >=rangeValue && item.Variants[0].Vehicles[0].Price<=853900






        //return item.Models[0].Variants[0].Vehicles[0].Price < rangeValue;
        //&& item.Models[0].Variants[0].Vehicles[0].Price < 599900 

        //const filterItem = JSON.parse(JSON.stringify(item));
        //return item.Models[0].Variants[0].Vehicles[0].Price  < rangeValue && item.Models[0].Variants[0].Vehicles[0].Price > 74000

        // for (const key in item.Models) {

        //    return item;
        // }



        // }) 

        //     console.log(filteredModel);
        //     console.log(state.allModels);
        //    commit('setallmodels', filteredModel);
    },

    postUserDetails({ commit }, payload) {

        return new Promise((resolve, reject) => {
            axios
                .post("https://preownedec.davinci-cms.com/po/submit.php",
                    payload)
                .then(response => {
                    resolve(response.data);
                    commit();

                })
                .catch(error => {
                    reject(error);
                    // console.log(error);
                });
        });


    }
}

const mutations = {

    setModels: (state, models) => (state.allModels = models),
    DataResponse: (state, data) => (state.DataResponse = data),

    setSearch: (state, search) => (state.allSearch[search[0]] = search[1]),
    setSelectedModel: (state, model) => (state.selectedModel = model),
    setimagesList: (state, images) => (state.imagesList = images),
    setVariants: (state, variants) => (state.Variants = variants),
    setallMakes: (state, allMakes) => (state.allMakes = allMakes),
    setallBodys: (state, allBodys) => (state.allBodys = allBodys),
    setAllModels: (state, AllModels) => (state.AllModels = AllModels),
    setAllTransmission: (state, AllTransmission) => (state.AllTransmission = AllTransmission),
    setAllColors: (state, AllColors) => (state.AllColors = AllColors),
    setAllEngine: (state, AllEngine) => (state.AllEngine = AllEngine),
    setAllCount: (state, AllCount) => (state.AllCount = AllCount),
    setloadingDone: (state, loadingDone) => (state.loadingDone = loadingDone),

    setallmodels: (state, models) => {
        //state.allModels = [];
        state.allModels = models
            //    console.log(state.allModels);
            // console.log(models);
    },

}
export default {
    state,
    getters,
    actions,
    mutations
}