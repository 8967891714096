<template>
  <!-- {{ allModels }} -- -->
  <section class="results mt-6">

    <div class=""  style="position:fixed; width:100%; height:100vh; left:0; top:0; z-index:100000; background:#fff; padding:10%" v-if="!loadingDone">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="rotate(0 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(30 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(60 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(90 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(120 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(150 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(180 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(210 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(240 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(270 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(300 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(330 50 50)">
          <rect x="46.5" y="21" rx="1.56" ry="1.56" width="7" height="12" fill="#c3012f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
          </rect>
        </g>
        </svg>
    </div>
    
    <div class="headingGroup">
      <div class="container">
        <div class="heading d-flex">
          <span>{{AllCount}} VEHICLES FOUND</span>
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <!-- <div class="row results__card"> -->
      <div v-for="models in allModels" :key="models.Id">
        <!-- {{allSearch.make}}{{models.Name}} -->
        <div class="" v-if="(allSearch.make == models.Name || allSearch.make=='' || allSearch.make=='ALL Makes')"
          v-for="model in models.Models" :key="model.Id">
          <!-- <div v-if="allSearch.male"> -->
            <div v-for="Variant in model.Variants" :key="Variant.Id"> 
              <div v-for="Vehicle in Variant.Vehicles" :key="Vehicle.StockId"> 

                <!-- <div class="row results__card" 
                v-if="
                (allSearch.body == Variant.Shape.Name || allSearch.body=='' || allSearch.body=='All Body')
                &&
               (allSearch.model == model.Name || allSearch.model=='' || allSearch.model=='All Models')
                &&
               (allSearch.color == Vehicle.Colour || allSearch.color=='' || allSearch.color=='All Colors')
                &&
               (allSearch.engine == Vehicle.Department || allSearch.engine=='' || allSearch.engine=='All Engine')
            
              &&
               (allSearch.price >= Vehicle.Price || allSearch.price=='' || allSearch.price==0)
            
              &&
               (allSearch.year >= Vehicle.Year || allSearch.year=='' || allSearch.year==0)
            
              &&
               (allSearch.mileage >= Vehicle.Mileage || allSearch.mileage=='' || allSearch.mileage==0)
                                                                                      
                
                "
                > -->

                <div class="row results__card" 
                v-if="
                (allSearch.body == Variant.Shape.Name || allSearch.body=='' || allSearch.body=='All Body')
                &&
               (allSearch.model == model.Name || allSearch.model=='' || allSearch.model=='All Models')
                &&
               (allSearch.color == Vehicle.Colour || allSearch.color=='' || allSearch.color=='All Colors')
                &&
               (allSearch.engine == Vehicle.Department || allSearch.engine=='' || allSearch.engine=='All Engine')
            
              &&

               ((allSearch.price[1] >= Vehicle.Price && allSearch.price[0] <= Vehicle.Price) ||  (allSearch.price[0] ==0 && allSearch.price[1] ==899900))
              &&
               ((allSearch.mileage[1] >= Vehicle.Mileage && allSearch.mileage[0] <= Vehicle.Mileage) || (allSearch.mileage[0] ==0 && allSearch.mileage[1] ==355000))
               
               &&
               ((allSearch.year[1] >= Vehicle.Year && allSearch.year[0] <= Vehicle.Year) ||  (allSearch.year[0]  ==2016 && allSearch.year[1]  ==2023))                                                                   
                
                "
                >
                  <div class="col-lg-3">
                    <div class="image__wrapper">

                      <div v-if="Vehicle.Images[0]">
<img 
                      v-lazy="{ 
                        src: Vehicle.Images[0].FullUrl, 
                        loading: '/Loading_icon.gif', 
                        error: '/noimg.jpg', 
                        delay: 500 
                      }" 
                      alt="Nissan" 
                    />
                      </div>
                      <div v-else>
                        <img src="/noimg.jpg" alt="Nissan" />
                      </div>
                      
                      <!-- <img :src="Vehicle.Images[0].FullUrl" class="h-100 w-100 object--contain"
                        alt="" /> -->
                    </div>
                    <!-- <div class="more__info">* More Vehicle Info</div> -->
                  </div>
                  <div class="col-lg-6">
                    <h3 class="title">{{ Variant.FullFriendlyName }}</h3>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="list">
                          <div class="list__items d-flex">
                            <div class="list__item-val">Mileage:</div>
                            <div class="list__item-val">
                              {{ Vehicle.Mileage }} KM
                            </div>
                          </div>
                          <div class="list__items d-flex">
                            <div class="list__item-val">Model year:</div>
                            <div class="list__item-val">
                              {{ Vehicle.Year }}
                            </div>
                          </div>
                          <!-- <div class="list__items d-flex">
                      <div class="list__item-val">Fuel type:</div>
                      <div class="list__item-val">{{Vehicle.Mileage}} </div>
                    </div>
                     <div class="list__items d-flex">
                      <div class="list__item-val">Engine:</div>
                      <div class="list__item-val">{{Vehicle.Mileage}} </div>
                    </div> -->
                          <div class="list__items d-flex">
                            <div class="list__item-val">Body Style:</div>
                            <div class="list__item-val">
                              {{ Variant.Shape.Name }}
                            </div>
                          </div>
                          <!-- <div class="list__items d-flex">
                            <div class="list__item-val">Transmission:</div>
                            <div class="list__item-val">
                              {{ Vehicle.Trim }}
                            </div>
                          </div> -->
                          <div class="list__items d-flex">
                            <div class="list__item-val">Exterior Color:</div>
                            <div class="list__item-val">
                              {{ Vehicle.Colour }}
                            </div>
                          </div>
                          <div class="list__items d-flex">
                            <div class="list__item-val">Stock Number:</div>
                            <div class="list__item-val">
                              {{ Vehicle.StockId }}
                            </div>
                          </div>
                          <!-- <div class="list__items d-flex">
                            <div class="list__item-val">VIN:</div>
                            <div class="list__item-val">
                              {{ Vehicle.VIN }}
                            </div>
                          </div> -->
            
                          <!-- <div class="list__items d-flex">
                      <div class="list__item-val">Comment:</div>
                      <div class="list__item-val">{{Vehicle.Comments}}</div>
                    </div> -->
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="address">
                          <!-- <div class="address__title">GLENDALE NISSAN</div>
                    <div class="address__actual">
                      484 NORTH AVE GLENDALE HEIGHTS, IL 60139
                    </div>
                    <div class="phone__number">630-469-6100</div>
                    <div class="link">Nissan Certified Pre-Owned</div>
                    <div class="link__sticker">Original Window Sticker</div>
                    <div class="sticker">
                      <img
                        src="https://picsum.photos/100"
                        class="h-100 w-100"
                        alt=""
                      />
                    </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="price__cancelled" v-if=" Vehicle.WasPrice>0">
                      <s>R {{ Vehicle.WasPrice }}</s>
                    </div>
                    <div class="price__actual">
                      R {{ priceWithCommas(Vehicle.Price) }}
                      <!-- {{ priceWithCommas(price) }} -->
                    </div>
                    <!-- <router-link :to="{
                      name: 'details',
                      params: {
                        model: models.Id,
                        id: model.Id,
                        stockID: Vehicle.StockId,
                      },
                    }" class="results__cta d-block">Vehicle details</router-link> -->
                    <button @click="redirectVehicleDetail(models, model, Vehicle, Variant)" class="results__cta d-block">Vehicle details</button>

                    <!-- <button class="results__cta d-block">Vehicle details</button> -->
                    <!-- <button class="results__cta d-block">Buy@Home</button> -->
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>


  </section>
  <!-- <paginate
  v-model="page"
    :page-count="20"
    :page-range="3"
    :margin-pages="2"
    :click-handler="clickCallback"
    :prev-text="'Prev'"
    :next-text="'Next'"
    :container-class="'pagination'"
    :page-class="'page-item'"
>
</paginate> -->


</template>
<script>
// import Paginate from "vuejs-paginate-next";

import { mapGetters, mapActions } from "vuex";
import { adobeAnalyticCode } from "../../analytics.js";

export default {
  name: "model-list",
  // components: { Paginate },
  methods: {
    ...mapActions(["fetchModels"]),

    priceWithCommas(price) {
      //  const price =  this.model.Variants[0].Vehicles[0].Price;
      //  console.log(price,"xxxx");
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
,
    redirectVehicleDetail (models, model, Vehicle, Variant) {

this.$router.push({
  name: 'details',
  params: {
    // model: models.Id,
    // id: model.Id,
    stockID: Vehicle.StockId,
  }
});

adobeAnalyticCode({
  'field_type': '',
  'category': Variant.Shape.Name,
  'make': models.Name,
  'model': model.Name,
  'vehicleid': Variant.Id,
  'language': "en",
  'version_name': Variant.FullFriendlyName,
});

}
  },
  computed: mapGetters(["allModels", "AllCount", "allSearch", "loadingDone"]),
  created() {
    this.fetchModels({});

  },
  data() {
    return {
      page: 10,
    };
  },
};
</script>
<style >
.object--cover {
  object-fit: cover;
}

.image__wrapper {
  /* height: 221px;
  width: 221px;
  display: inline-block;
  position: relative; */
}

.image__wrapper img {
  inset: 0;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.results__card {
  padding: 30px 0;
  border-bottom: dashed 1px #d9d9d9;
}

.title {
  font-weight: normal;
}

.list {
  padding-bottom: 30px;
}

.list .list__items {
  margin-bottom: 2px;
}

.list__item-val {
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 14px;
  font-family: "Nissan Light";

  text-align: left;
}

.address__title {
  font-size: 15px;
  color: #c3002f;
}

.address__actual {
  font-size: 14px;
  line-height: 1.5;
}

.phone__number {
  font-size: 22px;
  padding: 5px 0;
  line-height: 1.5;
  padding-right: 20px;
}

.link__sticker {
  font-size: 15px;
  color: #c3002f;
}

.sticker {
  height: 35px;
  width: 100px;
}

.sticker img {
  object-fit: cover;
}

.price__cancelled {
  font-size: 14px;
  line-height: 1.5;
  color: #8a8a8a;
  text-align: left;
}

.price__actual {
  font-size: 28px;
  color: #000;
  text-align: left;
}

.results__cta {
  background: #c3002f;
  color: #fff;
  padding: 12px 37px 12px 20px;
  border: none;
  width: 100%;
  margin: 10px 0;
  text-align: left;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
}

.results__cta::after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  background: url(../../assets/img/right-arrow-white.png) no-repeat right;
  background-size: 12px;
  top: 8px;
}

.results__cta:hover,
.results__cta:focus {
  background: #920023;
  color: #fff;
  text-decoration: none;
}

.headingGroup {
  display: flex;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.headingGroup .heading span {
  line-height: 1;
  word-wrap: break-word;
  font-size: 2.3em;
  letter-spacing: 0.11px;
  font-family: "Nissan Light";
  text-transform: uppercase;
  position: relative;
  padding-top: 17px;
}

.headingGroup .heading span::after {
  border-top: 4px solid #c3002f;
  content: " ";
  left: 0;
  top: 0;
  position: absolute;
  width: 50px;
}

.results.mt-6 {
  margin-top: 3.55em;
}

.results__card .title {
  font-size: 22px;
  font-family: "Nissan Light";
  text-align: left;
}
</style>