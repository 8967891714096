<template>
  <div>
    <section>
      <div class="container">
        <h1>NISSAN CERTIFIED PRE-OWNED VEHICLES</h1>
      </div>
    </section>
    <Home />
  </div>
</template>

<script>
  import Home from '../components/Home.vue'

  export default {
    name: 'App',
    components: {
      Home
    }
  }
</script>

<style>
  #app {
    margin-top: 60px;
  }

  h1 {
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 43px;
    font-family: "Nissan Light";
    text-align: left;
  }
</style>