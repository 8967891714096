
<template>
  <div class="filterRange">
    <label>{{ label}}</label>

    <Slider
  
    v-model="inputs.sliderValue"
    :max="maxvalue"
    :min="minvalue"
    :format="formating"
    showTooltip="drag"
    class="slider-red"
    @update="changeFoo($event)"
  />    

    <!-- <input
      class="styled-slider slider-progress"
      v-model="counter"
      type="range"
      :min="minvalue"
      :max="maxvalue"
      step="1"
      @change="changeFoo"
    /> -->
    <output id="rangevalue1">{{ "Min "+labeltext  +" "+ inputs.sliderValue[0] }} - {{ "Max "+ labeltext +" "+ inputs.sliderValue[1] }}</output>
  </div>
</template>


<script>
import Slider from '@vueform/slider'
export default {

  props: {
    counter: Number,
    minvalue: Number,
    maxvalue: Number,
    label: String,
    labeltext: String,
    format: String,
    key: String,
  },
  components: {
    Slider
  },
  
  mounted() {
    // for (let e of document.querySelectorAll(
    //   'input[type="range"].slider-progress'
    // )) {
    //   e.style.setProperty("--value", e.value);
    //   e.style.setProperty("--min", e.min == "" ? "0" : e.min);
    //   e.style.setProperty("--max", e.max == "" ? "100" : e.max);
    //   e.addEventListener("input", () =>
    //     e.style.setProperty("--value", e.value)
    //   );
    // }
if(this.label == "Price")
this.formating = { prefix: 'R'+' ', decimals: 0, thousand: ',' }

if(this.label == "Mileage")
this.formating = { prefix: 'KM'+' ', decimals: 0, thousand: '' }


  },
    computed: {
    },
    methods:{
      changeFoo(e){
        // console.log(this.inputs.sliderValue)
        this.$emit('changeFoo', this.inputs.sliderValue);
        //console.log(this.counter);
      }
    },
    
  data() {
    return {
      formating:{},
      inputs:{
        sliderValue: [this.minvalue, this.maxvalue],
      },
    };
  },
};
</script>

<style scoped>
@import '../../assets/price-slider.css';

.slider-red {
  margin: 30px 10px;
  margin-bottom: 0;

  --slider-connect-bg: #c3002f;
  --slider-tooltip-bg: #c3002f;
  --slider-handle-ring-color: #c3002f;
}


.filterRange {
      margin-right: 1em;
  display: flex;
  flex-direction: column;
}
.filterRange label {
    color: #343434;
    font-family: "Nissan Bold";
    display: block;
    margin: 2px 0 13px;
    text-align: left;
    font-size: 13px;
}
.filterRange output{
        text-align: left;
    font-size: 13px;
    font-family: 'Nissan Light';
    padding-top: 13px;
}
/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type="range"].styled-slider {
  height: 5px;
  -webkit-appearance: none;
  border: none;
}

/*progress support*/
input[type="range"].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
  
}

/*webkit*/
input[type="range"].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid #c3002f;
  /* box-shadow: 0 0 2px black; */
  margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - max(22px * 0.5, 1px));
}

input[type="range"].styled-slider::-webkit-slider-runnable-track {
  height: 8px;
  /* border: 1px solid #b2b2b2; */
  border-radius: 0.5em;
  background: #000;
  box-shadow: none;
}

input[type="range"].styled-slider:hover::-webkit-slider-runnable-track {
  background: #000;
}

input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#eee, #eee) 0 / var(--sx) 100% no-repeat,
    #c3002f;
}

/* input[type="range"].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#c3002f, #c3002f) 0 / var(--sx) 100% no-repeat,
    #c3002f;
} */

/*mozilla*/
input[type="range"].styled-slider::-moz-range-thumb {
  width: max(calc(2em - 1px - 1px), 0px);
  height: max(calc(2em - 1px - 1px), 0px);
  border-radius: 1em;
  background: #ffffff;
  border: 2px solid #c3002f;
  /* box-shadow: 0 0 2px black; */
}

input[type="range"].styled-slider::-moz-range-track {
  height: max(calc(1em - 1px - 1px), 0px);
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #ffffff;
  box-shadow: none;
}

input[type="range"].styled-slider:hover::-moz-range-track {
  background: #e5e5e5;
}

input[type="range"].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#c3002f, #c3002f) 0 / var(--sx) 100% no-repeat,
    #ffffff;
}

input[type="range"].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#c3002f, #c3002f) 0 / var(--sx) 100% no-repeat,
    #e5e5e5;
}

/*ms*/
input[type="range"].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #ffffff;
  border: 1px solid #c3002f;
  /* box-shadow: 0 0 2px black; */
  margin-top: 0;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type="range"].styled-slider:hover::-ms-track {
  background: #e5e5e5;
}

input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px), 0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #c3002f;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}
</style>